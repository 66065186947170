import React, {PropsWithChildren, useEffect, useState, useRef} from 'react';
import {Helmet} from "react-helmet";
import axios from "axios";
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import {useTranslation} from "react-i18next";
import Intro1 from "../components/serviceIntro/Intro1";
import Intro2 from "../components/serviceIntro/Intro2";

const Service = () => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [openMarket, setOpenMarket] = useState([]);
  const [generalMarket, setGeneralMaret] = useState([]);
  const [specialtyMarket, setSpecialtyMarket] = useState([]);
  const [selectedCate, setSelectedCate] = useState("");

  const ref = useRef<any>(null)
  const category = {1: "clothes", 2:"fashionAcc", 3: "shoes", 4: "jewerly",
    5: "sports", 6: "underwear", 7: "household", 8: "food",
    9: "beauty",10: "interior", 11: "appliance", 12: "pet",
    13: "kidsClothes", 14: "kidsToy", 15: "books",16: "custom"}

  function mallSkeleton({ children }: PropsWithChildren<unknown>) {
    return (
      <div
        style={{
          display: 'block',
          lineHeight: 2,
          padding: '1rem',
          marginBottom: '0.5rem'
        }}
      >
        {children}
      </div>
    )
  }

  const getServiceInfo = async (num : any, name: string) => {
  try {
    const { data, status } = await axios.get(`${process.env.REACT_APP_PROXY}/homepage/categorys/${num}`);
    if( status === 200 ) {

      setLoading(true);

      setTimeout(() => {
        setSelectedCate(name)
        // console.log("name : ",name);
        setLoading(false);
        setOpenMarket(data.shop_malls?.open);
        setGeneralMaret(data.shop_malls?.general);
        setSpecialtyMarket(data.shop_malls?.specialty);
      }, 500)
    }
  }
  catch(error) {
    // @ts-ignore
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    error.response.data;
    setLoading(false);
  }
}
  useEffect(() => {
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
     getServiceInfo(1,"clothes")
      // eslint-disable-next-line
  }, []);

  return (
    <div>
      <Helmet>
        <title>{t('pageTitle.service')}{t('pageTitle.common')}</title>
      </Helmet>
      <div>
        <div className="player-wrapper">
          <div className="serviceTitle"><span dangerouslySetInnerHTML={{__html:`${t('service.serviceTitle')}`}} /> </div>
        </div>
      </div>

        <div className="category_container" id="category_list" >
          <div className="subPage">
          <div className="category_text_container">
            <div className="item_cate_title" dangerouslySetInnerHTML={{__html:`${t('service.title')}`}}/>
            <div className="item_cate_subtitle" dangerouslySetInnerHTML={{__html:`${t('service.title2')}`}}/>
          </div>
          <div className="category_list">
            <div className="category_line" ref={ref}>
              {Object.entries(category).map((name, idx) => {
                // return <button key={idx} className={`cateIcon icon_${name[0]}`} onClick={() => getServiceInfo(name[0],name[1])}>{t(`service.${name[1]}`)}</button>
                return <button key={idx} className={name[1] === selectedCate ? "cateIconSelected" : "cateIcon"} onClick={() => getServiceInfo(name[0],name[1])}>{t(`service.${name[1]}`)}{t(`service.${name[1]+"Icon"}`)}</button>
              })}
            </div>
          </div>
          <div className="mall_list">
            {/*{ selectedCate &&*/}
            {/*  <p className="recommend_title">{t(`service.${selectedCate}`)} 판매자 분들께 추천하는 플랜이에요</p>*/}
            {/*}*/}
            <section className="recommend">
              {/*<div>*/}
              {/*  <div className="recommend_plan">*/}
              {/*    <p>전문 판매자라면</p>*/}
              {/*    <p>플러스 플랜</p>*/}
              {/*    <p>대충 수수료</p>*/}
              {/*    <div>자세히보기</div>*/}
              {/*  </div>*/}
              {/*</div>*/}
              <div className="total_mall_list">
                {!openMarket ? "" :
                  <div className="recommend_mall">
                    <div className="mall_title_box">
                      <h3 className="selected_mall_title">{t('service.open')}</h3><h3 className="text_center">-</h3>
                    </div>

                    {loading
                      ?
                      <div className="mallSkeletons">
                        <Skeleton wrapper={mallSkeleton} width={220} height={24} count={1} />
                        <Skeleton wrapper={mallSkeleton} width={180} height={24} count={1} />
                        <Skeleton wrapper={mallSkeleton} width={200} height={24} count={1} />
                      </div>
                      :
                      <ul className="recommend_mall_ul">
                        {openMarket &&
                          openMarket.map((news: any, i: any) => (
                            <li className="selected_mall_list" key={i}><img alt="logo" src={news.logo}/></li>
                          ))}
                      </ul>
                    }
                  </div>
                }
                {!specialtyMarket ? "" :
                  <div className="recommend_mall_2">
                    <div className="mall_title_box">
                      <h3 className="selected_mall_title">{t('service.specialty')}</h3><h3
                      className="text_center">-</h3>
                    </div>

                    {loading
                      ?
                      <div className="mallSkeletons">
                        <Skeleton wrapper={mallSkeleton} width={120} height={28} count={1} />
                      </div>
                      :
                      <ul className="recommend_mall_ul">
                        {specialtyMarket &&
                          specialtyMarket.map((news: any, i: any) => (
                            <li className="selected_mall_list" key={i}><img alt="logo" src={news.logo}/></li>
                          ))}
                      </ul>
                    }
                  </div>
                }
                {!generalMarket ? "" :
                  <div className="recommend_mall">
                    <div className="mall_title_box">
                      <h3 className="selected_mall_title">{t('service.general')}</h3><h3
                      className="text_center">-</h3>
                    </div>

                    {loading
                      ?
                      <div className="mallSkeletons">
                        <Skeleton wrapper={mallSkeleton} width={180} height={28} count={1} />
                        <Skeleton wrapper={mallSkeleton} width="90%" height={28} count={1} />
                        <Skeleton wrapper={mallSkeleton} width={200} height={28} count={1} />
                      </div>
                      :
                      <ul className="recommend_mall_ul">
                        {generalMarket &&
                          generalMarket.map((news: any, i: any) => (
                            <li className="selected_mall_list" key={i}><img alt="logo" src={news.logo}/></li>
                          ))}
                      </ul>
                    }
                  </div>
                }
              </div>
            </section>
          </div>
          </div>
        </div>


      <Intro1/>

      <Intro2/>

    </div>
  )
}
export default Service;