import React, {useState} from 'react';
import {Helmet} from 'react-helmet';
// import {Link} from 'react-router-dom';
import { HashLink } from 'react-router-hash-link'
import 'react-loading-skeleton/dist/skeleton.css';
// @ts-ignore
// import dashboardImg from '../assets/images/dashboard@2x.png';
import Marquee from "react-fast-marquee";
import Modal from 'react-modal';
import {Swiper, SwiperSlide} from 'swiper/react';
import "swiper/css";
import "swiper/css/pagination";
import {CustomerVideo} from "../components/video";
import Partners from '../components/main/Partners';
import {isMobile} from "react-device-detect";
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive'
// import FreeTrial from "../components/main/FreeTrial";

const Home = () => {
  const { t } = useTranslation();
  const [activeIndex, setActiveIndex] = useState(0);
  const tabClickHandler=(index: any)=>{
    setActiveIndex(index);
  };

  const [openModal1, setOpenModal1] = useState(false);
  const [openModal2, setOpenModal2] = useState(false);
  const [openModal3, setOpenModal3] = useState(false);
  const [openModal4, setOpenModal4] = useState(false);
  const [openModal5, setOpenModal5] = useState(false);

  //const [mobileCheck, setMobileCheck] = useState(0);

  const MallNames = [
    ['coupang', 'gmarket', 'ssg'],
    ['auction', 'kakaotalkstore', '11st'],
    ['lotteon', 'smart-store'],
    ['cj-mall', 'hmall', 'gs-shop'],
    ['lottemall', 'home-shopping', 'fashionplus'],
    ['halfclub', 'lately'],
  ];

  const modalStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)'
    },
  };
  Modal.setAppElement('#root');

  const MallsLIST = (props: any) => {
    return (
      <ul className="bundleMall">
        {props.names.map((name: any) => (<li key={name}><img src={`/assets/images/logo-${name}.png`} alt={name} /></li>))}
      </ul>
    )
  };

  const isDesktopOrLaptop = useMediaQuery({
    query: '(min-width: 1024px)'
  });
  const isTabletOrMobile = useMediaQuery({
    query: '(max-width: 1023px)'
  });

  // useEffect(() => {
  //   return  isMobile ? setMobileCheck(2): setMobileCheck(3)
  // }, []);

  // @ts-ignore
  return (
    <div className="homePage">
      <Helmet>
        <title>{t('pageTitle.home')}{t('pageTitle.common')}</title>
      </Helmet>

      <div className="main-contents">

        <section className="mainIntro">
          <div className="contents-container">
            {isTabletOrMobile &&
              <div className="mainMobileMovieClip">
                <video width="100%" height="100%" muted playsInline autoPlay loop id="introVideo" poster={"/assets/NONE.mp4"}>
                  <source src="/assets/NONE.mp4" type="video/mp4"/>
                </video>
              </div>
            }
            <div className="headLine">
              <h2 dangerouslySetInnerHTML={{__html:`${t('mainPage.headline')}`}}></h2>
              {isMobile
                ?
                <div className="buttonContainer">
                <a href="https://admin.sellerhub.co.kr/shop/admin_provider/member/join_simplification_mo.php" target="_blank" id="button-main-start-mobile" className="startLink" rel="noreferrer">
                  {t('mainPage.getStarted')}
                </a>
                <a href="https://page.stibee.com/subscriptions/223519" target="_blank" id="button-main-start-mobile" className="proposalLink" rel="noreferrer">
                  {t('mainPage.getProposals')}
                </a>
                </div>
                :
                <div className="buttonContainer">
                <a href="https://admin.sellerhub.co.kr/shop/admin_provider/member/join_simplification.php" target="_blank" id="button-main-start-pc" className="startLink" rel="noreferrer">
                  {t('mainPage.getStarted')}
                </a>
                <a href="https://page.stibee.com/subscriptions/223519" target="_blank" id="button-main-start-pc" className="proposalLink" rel="noreferrer">
              {t('mainPage.getProposals')}
                </a>
                </div>
              }
            </div>
            {isDesktopOrLaptop &&
              <div className="mainMovieClip">
                <video width="100%" height="100%" muted playsInline autoPlay loop id="introVideo">
                  <source src="/assets/main_cut2.mp4" type="video/mp4"/>
                </video>
              </div>
            }
          </div>
        </section>

        <section className="howToSalesSection">

          <div className="salesContainer">
            {/*<Fade direction="up" triggerOnce={true} duration={500}>*/}
            <h2 dangerouslySetInnerHTML={{__html:`${t('mainPage.howToSales')}`}}></h2>
            {/*</Fade>*/}
            <div className="navBtn">
              <button className={activeIndex===0 ? "pannelMoveBtn active" : "pannelMoveBtn"} onClick={()=>tabClickHandler(0)}>{t('mainPage.salesTabTitle01')}</button>
              <button className={activeIndex===1 ? "pannelMoveBtn active" : "pannelMoveBtn"} onClick={()=>tabClickHandler(1)}>{t('mainPage.starterTabTitle02')}</button>
              {/*<button className={activeIndex===2 ? "pannelMoveBtn active" : "pannelMoveBtn"} onClick={()=>tabClickHandler(2)}>{t('mainPage.outletTabTitle03')}</button>*/}
            </div>

            <div className={activeIndex===0 ? "salesCard active" : "salesCard"}>
              <Swiper
                slidesPerView={1}
                spaceBetween={16}
                observer={true}
                observeParents={true}
                breakpoints={{
                  "@0.00": {
                    slidesPerView: 1,
                    spaceBetween: 16,
                  },
                  "@0.5": {
                    slidesPerView: 2,
                    spaceBetween: 16,
                  },
                  "@1.00": {
                    slidesPerView: 3,
                    spaceBetween: 16,
                  }
                }}
                className="mySwiper"
              >
                <SwiperSlide>
                  {/*<Fade direction="up" triggerOnce={true} duration={500} cascade={true}>*/}
                  <div className="cardItem">
                    <h3 dangerouslySetInnerHTML={{__html:`${t('mainPage.salesTab01.title')}`}}/>
                    <p className="tagList" dangerouslySetInnerHTML={{__html:`${t('mainPage.salesTab01.tag')}`}}></p>
                    {/*<div className="thumbNail">*/}
                    {/*  <img src="/assets/images/index_image1.png" alt="" />*/}
                    {/*</div>*/}
                  </div>
                  {/*</Fade>*/}
                </SwiperSlide>

                <SwiperSlide>
                  {/*<Fade direction="up" triggerOnce={true} duration={750} cascade={true}>*/}
                  <div className="cardItem">
                    <h3 dangerouslySetInnerHTML={{__html:`${t('mainPage.salesTab02.title')}`}}></h3>
                    <p className="tagList"
                       dangerouslySetInnerHTML={{__html:`${t('mainPage.salesTab02.tag')}`}}
                    ></p>
                    {/*<div className="thumbNail">*/}
                    {/*  <img src="/assets/images/index_image2.png" alt="" />*/}
                    {/*</div>*/}
                  </div>
                  {/*</Fade>*/}
                </SwiperSlide>

                <SwiperSlide>
                  {/*<Fade direction="up" triggerOnce={true} duration={1000} cascade={true}>*/}
                  <div className="cardItem">
                    <h3 dangerouslySetInnerHTML={{__html:`${t('mainPage.salesTab03.title')}`}}></h3>
                    <p className="tagList" dangerouslySetInnerHTML={{__html:`${t('mainPage.salesTab03.tag')}`}}></p>
                    {/*<div className="thumbNail">*/}
                    {/*  <img src="/assets/images/index_image3.png" alt="" />*/}
                    {/*</div>*/}
                  </div>
                  {/*</Fade>*/}
                </SwiperSlide>
              </Swiper>

            </div>

            <div className={activeIndex===1 ? "salesCard active" : "salesCard"}>
              <Swiper
                slidesPerView={1}
                spaceBetween={16}
                observer={true}
                observeParents={true}
                breakpoints={{
                  "@0.00": {
                    slidesPerView: 1,
                    spaceBetween: 16,
                  },
                  "@0.5": {
                    slidesPerView: 2,
                    spaceBetween: 16,
                  },
                  "@1.00": {
                    slidesPerView: 3,
                    spaceBetween: 16,
                  }
                }}
                className="mySwiper"
              >
                <SwiperSlide>
                  <div className="cardItem">
                    <h3 dangerouslySetInnerHTML={{__html:`${t('mainPage.staterTab01.title')}`}}></h3>
                    <p className="tagList"
                       dangerouslySetInnerHTML={{__html:`${t('mainPage.staterTab01.tag')}`}}
                    ></p>
                    {/*<div className="thumbNail">*/}
                    {/*  <img src="/assets/images/index_image4.png" alt="" />*/}
                    {/*</div>*/}
                  </div>
                </SwiperSlide>

                <SwiperSlide>
                  <div className="cardItem">
                    <h3 dangerouslySetInnerHTML={{__html:`${t('mainPage.staterTab02.title')}`}}></h3>
                    <p className="tagList"
                       dangerouslySetInnerHTML={{__html:`${t('mainPage.staterTab02.tag')}`}}
                    >
                    </p>
                    {/*<div className="thumbNail">*/}
                    {/*  <img src="/assets/images/index_image5.png" alt="" />*/}
                    {/*</div>*/}
                  </div>
                </SwiperSlide>

                <SwiperSlide>
                  <div className="cardItem">
                    <h3 dangerouslySetInnerHTML={{__html:`${t('mainPage.staterTab03.title')}`}}></h3>
                    <p className="tagList"
                       dangerouslySetInnerHTML={{__html:`${t('mainPage.staterTab03.tag')}`}}
                    >
                    </p>
                    {/*<div className="thumbNail">*/}
                    {/*  <img src="/assets/images/index_image6.png" alt="" />*/}
                    {/*</div>*/}
                  </div>
                </SwiperSlide>
              </Swiper>

            </div>

            <div className={activeIndex===2 ? "salesCard active" : "salesCard"}>

              <Swiper
                slidesPerView={"auto"}
                spaceBetween={16}
                observer={true}
                observeParents={true}
                breakpoints={{
                  "@0.00": {
                    slidesPerView: 1,
                    spaceBetween: 16,
                  },
                  "@0.5": {
                    slidesPerView: 2,
                    spaceBetween: 16,
                  },
                  "@1.00": {
                    slidesPerView: 3,
                    spaceBetween: 16,
                  }
                }}
                className="mySwiper"
              >
                <SwiperSlide>
                  <div className="cardItem">
                    <h3 dangerouslySetInnerHTML={{__html:`${t('mainPage.outletTab01.title')}`}}></h3>
                    <p className="tagList"
                       dangerouslySetInnerHTML={{__html:`${t('mainPage.outletTab01.tag')}`}}
                    >
                    </p>
                    <div className="thumbNail">
                      <img src="/assets/images/index_image7.png" alt="" />
                    </div>
                  </div>
                </SwiperSlide>

                <SwiperSlide>
                  <div className="cardItem">
                    <h3 dangerouslySetInnerHTML={{__html:`${t('mainPage.outletTab02.title')}`}}></h3>
                    <p className="tagList"
                       dangerouslySetInnerHTML={{__html:`${t('mainPage.outletTab02.tag')}`}}
                    >
                    </p>
                    <div className="thumbNail">
                      <img src="/assets/images/index_image8.png" alt="" />
                    </div>
                  </div>
                </SwiperSlide>

                <SwiperSlide>
                  <div className="cardItem">
                    <h3 dangerouslySetInnerHTML={{__html:`${t('mainPage.outletTab03.title')}`}}></h3>
                    <p className="tagList" dangerouslySetInnerHTML={{__html:`${t('mainPage.outletTab03.tag')}`}}>
                    </p>
                    <div className="thumbNail">
                      <img src="/assets/images/index_image9.png" alt="" />
                    </div>
                  </div>
                </SwiperSlide>
              </Swiper>

            </div>

          </div>

        </section>


        <section className="salesChannelSection">
          <h2 dangerouslySetInnerHTML={{__html:`${t('mainPage.salesChannel.title')}`}}></h2>
          <p className="description">{t('mainPage.salesChannel.desc')}</p>
          {/*<Link to={{pathname: "/service", hash: "category"}} className="linkButton">상품별 판매 채널 확인하기</Link>*/}
          <HashLink scroll={(el) => el.scrollIntoView({ behavior: 'smooth' })}
                    to={'/service#category_list'} id="button-link-channels" className="linkButton">{t('mainPage.salesChannel.linkButton')}</HashLink>
          <div className="mallList">
            <Marquee speed={40}>
              {MallNames.map((names: any) => (<MallsLIST className="overlay" key={names} names={names} />)).slice(0, 3)}
            </Marquee>

            <Marquee style={{marginTop: 40, marginBottom: 40}} speed={30} direction="right">
              {MallNames.map((names: any) => (<MallsLIST className="overlay" key={names} names={names} />)).slice(4, 6)}
            </Marquee>

            <Marquee speed={50}>
              {MallNames.map((names: any) => (<MallsLIST className="overlay" key={names} names={names} />)).slice(7, 10)}
            </Marquee>
          </div>
        </section>

        <section className="manageInfoSection">
          <div className="subPage">
            <h2 dangerouslySetInnerHTML={{__html:`${t('mainPage.intManaging.title')}`}}></h2>

            {/*<div className="image"><img src={dashboardImg} width="800" alt="" /></div>*/}
            <div className="detailButton">
              <HashLink scroll={(el) => el.scrollIntoView({ behavior: 'smooth' })}
                        to={'/service#category_intro1_title'} id="button-link-channels" className="HashLink">{t('mainPage.whySellerhub.detailView')}</HashLink>
            </div>
            <div className="managingInfo">
              <Swiper
                slidesPerView={1}
                spaceBetween={16}
                observer={true}
                observeParents={true}
                breakpoints={{
                  "@0.00": {
                    slidesPerView: 1,
                    spaceBetween: 16,
                  },
                  "@0.5": {
                    slidesPerView: 2,
                    spaceBetween: 16,
                  },
                  "@1.00": {
                    slidesPerView: 3,
                    spaceBetween: 16,
                  }
                }}
                className="mySwiper"
              >
                <SwiperSlide>
                  {/*<Fade direction="up" triggerOnce={true} duration={500} cascade={true}>*/}
                  <li>
                  <h3 dangerouslySetInnerHTML={{__html:`${t('mainPage.intManaging.card01.title')}`}}></h3>
                  <p className="tagList"
                     dangerouslySetInnerHTML={{__html:`${t('mainPage.intManaging.card01.tag')}`}}
                  >
                  </p>
                  <div className="thumbNail">
                    <img src="/assets/images/index_image1.png" alt="" />
                  </div>
                  {/*</Fade>*/}
                  </li>
                </SwiperSlide>

                <SwiperSlide>
                  {/*<Fade direction="up" triggerOnce={true} duration={750} cascade={true}>*/}
                  <li>
                  <h3 dangerouslySetInnerHTML={{__html:`${t('mainPage.intManaging.card02.title')}`}}></h3>
                  <p className="tagList" dangerouslySetInnerHTML={{__html:`${t('mainPage.intManaging.card02.tag')}`}}>
                  </p>
                  <div className="thumbNail">
                    <img src="/assets/images/index_image2.png" alt="" />
                  </div>
                  {/*</Fade>*/}
                  </li>
                </SwiperSlide>

                <SwiperSlide>
                  <li>
                  {/*<Fade direction="up" triggerOnce={true} duration={1000} cascade={true}>*/}
                  <h3 dangerouslySetInnerHTML={{__html:`${t('mainPage.intManaging.card03.title')}`}}></h3>
                  <p className="tagList"
                     dangerouslySetInnerHTML={{__html:`${t('mainPage.intManaging.card03.tag')}`}}
                  >
                  </p>
                  <div className="thumbNail">
                    <img src="/assets/images/index_image3.png" alt="" />
                  </div>
                  </li>
                </SwiperSlide>
              </Swiper>
            </div>
          </div>
        </section>

        <section className="whySellerhubSection">
          <div className="container">
            <h2 dangerouslySetInnerHTML={{__html:`${t('mainPage.whySellerhub.title')}`}}></h2>
            <p className="description"
               dangerouslySetInnerHTML={{__html:`${t('mainPage.whySellerhub.desc')}`}}
            ></p>

            <div className="infoContaniner">
              <Swiper
                slidesPerView={1}
                spaceBetween={10}
                breakpoints={{
                  "@0.00": {
                    slidesPerView: 1,
                    spaceBetween: 0,
                  },
                  "@0.749": {
                    slidesPerView: 2,
                    spaceBetween: 0,
                  },
                  "@0.75": {
                    slidesPerView: 5,
                    spaceBetween: 0,
                  },
                  "@1.00": {
                    slidesPerView: 5,
                    spaceBetween: 16,
                  }
                }}
                className="mySwiper"
              >
                <SwiperSlide>
                  <div className="whyShInfo">
                    <div className="item" onClick={()=> setOpenModal1(true)}>
                      <img src="/assets/images/stopwatch@2x.png" alt="Stop watch" />
                      <h3 dangerouslySetInnerHTML={{__html:`${t('mainPage.whySellerhub.card01.title')}`}}></h3>
                      <button className="view">{t('mainPage.whySellerhub.detailView')}</button>
                    </div>

                    <Modal
                      isOpen={openModal1}
                      onRequestClose={()=> setOpenModal1(false)}
                      style={modalStyles}
                      contentLabel="Modal"
                    >
                      <div className="modalContents">
                        <button className="layerClose" onClick={()=> setOpenModal1(false)}><img src="./assets/images/close@2x.png" alt="Close" /></button>
                        <p className="icon"><img src="/assets/images/stopwatch@2x.png" alt="" /></p>
                        <h3 dangerouslySetInnerHTML={{__html:`${t('mainPage.whySellerhub.card01.modalTitle')}`}}></h3>

                        <div className="detail">
                          <div className="detailContents">
                            <div className="desc" dangerouslySetInnerHTML={{__html:`${t('mainPage.whySellerhub.card01.noneSHDesc')}`}}></div>
                            <h4>{t('mainPage.whySellerhub.noneSH')}</h4>
                            <div className="image"><img src="/assets/images/image_service1_1.png" alt="" /></div>
                          </div>

                          <div className="detailContents">
                            <div className="desc">{t('mainPage.whySellerhub.card01.withSHDesc')}</div>
                            <h4>{t('mainPage.whySellerhub.withSH')}</h4>
                            <div className="image"><img src="/assets/images/image_service1_2.png" alt="" /></div>
                          </div>
                        </div>
                      </div>
                    </Modal>
                  </div>
                </SwiperSlide>

                <SwiperSlide>
                  <div className="whyShInfo small">
                    <div className="item" onClick={()=> setOpenModal2(true)}>
                      <img src="/assets/images/office_building@2x.png" alt="Office building" />
                      <h3 dangerouslySetInnerHTML={{__html:`${t('mainPage.whySellerhub.card02.title')}`}}></h3>
                      <button className="view">{t('mainPage.whySellerhub.detailView')}</button>
                    </div>

                    <Modal
                      isOpen={openModal2}
                      onRequestClose={()=> setOpenModal2(false)}
                      style={modalStyles}
                      contentLabel="Modal"
                    >
                      <div className="modalContents">
                        <button className="layerClose" onClick={()=> setOpenModal2(false)}><img src="./assets/images/close@2x.png" alt="Close" /></button>
                        <p className="icon"><img src="/assets/images/office_building@2x.png" alt="" /></p>
                          <h3 dangerouslySetInnerHTML={{__html:`${t('mainPage.whySellerhub.card02.modalTitle')}`}}></h3>
                        <div className="detail">
                          <div className="detailContents">
                            <div className="desc">{t('mainPage.whySellerhub.card02.noneSHDesc')}</div>
                            <h4>{t('mainPage.whySellerhub.noneSH')}</h4>
                            <div className="image"><img src="/assets/images/image_service2_1.png" alt="" /></div>
                          </div>

                          <div className="detailContents">
                            <div className="desc">{t('mainPage.whySellerhub.card02.withSHDesc')}</div>
                            <h4>{t('mainPage.whySellerhub.withSH')}</h4>
                            <div className="image"><img src="/assets/images/image_service2_2.png" alt="" /></div>
                          </div>
                        </div>
                      </div>
                    </Modal>
                  </div>
                </SwiperSlide>

                <SwiperSlide>
                  <div className="whyShInfo small">
                    <div className="item" onClick={()=> setOpenModal3(true)}>
                      <img src="/assets/images/ok_hand@2x.png" alt="OK hand" />
                      <h3 dangerouslySetInnerHTML={{__html:`${t('mainPage.whySellerhub.card03.title')}`}}/>
                      <button className="view">{t('mainPage.whySellerhub.detailView')}</button>
                    </div>

                    <Modal
                      isOpen={openModal3}
                      onRequestClose={()=> setOpenModal3(false)}
                      style={modalStyles}
                      contentLabel="Modal"
                    >
                      <div className="modalContents">
                        <button className="layerClose" onClick={()=> setOpenModal3(false)}><img src="./assets/images/close@2x.png" alt="Close" /></button>
                        <p className="icon"><img src="/assets/images/ok_hand@2x.png" alt="" /></p>
                        <h3 dangerouslySetInnerHTML={{__html:`${t('mainPage.whySellerhub.card03.modalTitle')}`}}/>

                        <div className="detail">
                          <div className="detailContents">
                            <div className="desc">{t('mainPage.whySellerhub.card03.noneSHDesc')}</div>
                            <h4>{t('mainPage.whySellerhub.noneSH')}</h4>
                            <div className="image"><img src="/assets/images/image_service3_1.png" alt="" /></div>
                          </div>

                          <div className="detailContents">

                            <div className="desc">{t('mainPage.whySellerhub.card03.withSHDesc')}
                            </div>
                            <h4>{t('mainPage.whySellerhub.withSH')}</h4>
                            <div className="image"><img src="/assets/images/image_service3_2.png" alt="" /></div>
                          </div>
                        </div>
                      </div>
                    </Modal>
                  </div>
                </SwiperSlide>

                <SwiperSlide>
                  <div className="whyShInfo large">
                    <div className="item" onClick={()=> setOpenModal4(true)}>
                      <img src="/assets/images/sparkles@2x.png" alt="OK hand" />
                      <h3 dangerouslySetInnerHTML={{__html:`${t('mainPage.whySellerhub.card04.title')}`}}></h3>
                      <button className="view">{t('mainPage.whySellerhub.detailView')}</button>
                    </div>

                    <Modal
                      isOpen={openModal4}
                      onRequestClose={()=> setOpenModal4(false)}
                      style={modalStyles}
                      contentLabel="Modal"
                    >
                      <div className="modalContents">
                        <button className="layerClose" onClick={()=> setOpenModal4(false)}><img src="./assets/images/close@2x.png" alt="Close" /></button>
                        <p className="icon"><img src="/assets/images/sparkles@2x.png" alt="" /></p>
                        <h3 dangerouslySetInnerHTML={{__html:`${t('mainPage.whySellerhub.card04.modalTitle')}`}}></h3>
                        <div className="detail">
                          <div className="detailContents">
                            <div className="desc">{t('mainPage.whySellerhub.card04.noneSHDesc')}</div>
                            <h4>{t('mainPage.whySellerhub.noneSH')}</h4>
                            <div className="image"><img src="/assets/images/image_service4_1.png" alt="" /></div>
                          </div>

                          <div className="detailContents">
                            <div className="desc">{t('mainPage.whySellerhub.card04.withSHDesc')}</div>
                            <h4>{t('mainPage.whySellerhub.withSH')}</h4>
                            <div className="image"><img src="/assets/images/image_service4_2.png" alt="" /></div>
                          </div>
                        </div>
                      </div>
                    </Modal>
                  </div>
                </SwiperSlide>

                <SwiperSlide>
                  <div className="whyShInfo large">
                    <div className="item" onClick={()=> setOpenModal5(true)}>
                      <img src="/assets/images/handshake_light@2x.png" alt="OK hand" />
                      <h3 dangerouslySetInnerHTML={{__html:`${t('mainPage.whySellerhub.card05.title')}`}}></h3>
                      <button className="view">{t('mainPage.whySellerhub.detailView')}</button>
                    </div>

                    <Modal
                      isOpen={openModal5}
                      onRequestClose={()=> setOpenModal5(false)}
                      style={modalStyles}
                      contentLabel="Modal"
                    >
                      <div className="modalContents">
                        <button className="layerClose" onClick={()=> setOpenModal5(false)}><img src="./assets/images/close@2x.png" alt="Close" /></button>
                        <p className="icon"><img src="/assets/images/handshake_light@2x.png" alt="" /></p>
                        <h3 dangerouslySetInnerHTML={{__html:`${t('mainPage.whySellerhub.card05.modalTitle')}`}}></h3>
                        <div className="detail">
                          <div className="detailContents">

                            <div className="desc">{t('mainPage.whySellerhub.card05.noneSHDesc')}</div>
                            <h4>{t('mainPage.whySellerhub.noneSH')}</h4>
                            <div className="image"><img src="/assets/images/image_service5_1.png" alt="" /></div>
                          </div>

                          <div className="detailContents">

                            <div className="desc">{t('mainPage.whySellerhub.card05.withSHDesc')}</div>
                            <h4>{t('mainPage.whySellerhub.withSH')}</h4>
                            <div className="image"><img src="/assets/images/image_service5_2.png" alt="" /></div>
                          </div>
                        </div>
                      </div>
                    </Modal>
                  </div>
                </SwiperSlide>
              </Swiper>

            </div>
          </div>

        </section>

        <section className="customerHistorySection">
          <CustomerVideo />
        </section>

        <section className="withSellerhubSection">
          <Partners />
        </section>

        {/*<section className="proposal">*/}
        {/*  <Proposal/>*/}
        {/*</section>*/}
      </div>
    </div>
  )
}


export default Home;