import React from 'react';
import { useTranslation } from "react-i18next";

const Partners = () => {
  const partners = [2,3,4,0,8,9,0,11,12];
  const { t } = useTranslation();

  return (
    <div className="container">
      <ul className="partnerList">
        {partners.map((names: any) => (
          <li key={names}><img src={`/assets/images/main_partner_logo_${names}.png`} alt="" /></li>
        ))}
      </ul>

      <div className="partnersInfo" dangerouslySetInnerHTML={{__html: `${t('mainPage.partnersInfo.title')}`}}>

      </div>
    </div>
  )
}
export default Partners;