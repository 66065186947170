import React, {useEffect, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {NavLink, useLocation} from 'react-router-dom';
import {motion, useCycle} from "framer-motion";
import {useDimensions} from "./use-dimensions";
import {MenuToggle} from "./MenuToggle";
import {Cookies} from "react-cookie";
import TopBanner from "./TopBanner";
import TagManager from 'react-gtm-module';


const sidebar = {
  open: (height = 1000) => ({
    clipPath: `circle(${height * 2 + 200}px at calc(100% - 40px) 40px)`,
    transition: {
      delay: 0.1,
      type: "tween",
      stiffness: 20,
      restDelta: 23
    }
  }),
  closed: {
    clipPath: "circle(1px at calc(100% - 40px) 40px)",
    transition: {
      delay: 0.1,
      type: "spring",
      stiffness: 400,
      damping: 40
    }
  }
};

const variants = {
  open: {
    transition: { staggerChildren: 0.07, delayChildren: 0.2 }
  },
  closed: {
    transition: { staggerChildren: 0.05, staggerDirection: -1 }
  }
};

const variantsItems = {
  open: {
    y: 0,
    opacity: 1,
    transition: {
      y: { stiffness: 300, velocity: -100 }
    }
  },
  closed: {
    y: 50,
    opacity: 0,
    transition: {
      y: { stiffness: 300 }
    }
  }
};
TagManager.initialize({
  gtmId: 'GTM-PCRLZH9'
});



const Header = (onClick: any) => {
  const setCookie = () => {
    const cookies = new Cookies();
    cookies.set('inflow_referer', window.location.search.substr(1), {path: '/', domain: '.sellerhub.co.kr'});
  }
  const eraseCookie = () => {
    const cookies = new Cookies();
    cookies.set('inflow_referer', '', {path: '/', domain: '.sellerhub.co.kr', maxAge: 0})
  }

  if (window.location.search && window.location.search.includes('utm_source')) {
    setCookie();
  } else {
    eraseCookie();
  }

  const { t } = useTranslation();
  const { pathname } = useLocation();
  const [headerClassName, setHeaderClassName] = useState("");//change className from scrollY event
  const [isOpen, toggleOpen] = useCycle(false, true);
  const containerRef = useRef(null);
  const { height } = useDimensions(containerRef);
  const [isMobile, setIsMobile] = useState(false);

  const [gtmUrl, setGtmUrl] = useState("");

  useEffect(() => {
      if (window.location.search && window.location.search.includes('utm_source')) {
        const origin = window.location.search.substr(1)
        /* eslint-disable no-useless-escape */
        setGtmUrl(JSON.stringify(origin).replace(/\"/gi, ""));
      return
    }
  }, [gtmUrl])



  const handleScroll = (headerClassName: string) => {
    if(isMobile){
      if (headerClassName !== 'menu-scroll' && window.scrollY >= 72) {
        setHeaderClassName('menu-scroll');
      } else if (headerClassName === 'menu-scroll' && window.scrollY < 72) {
        setHeaderClassName('');
      }
    } else {
      if (headerClassName !== 'menu-scroll' && window.scrollY >= 80) {
        setHeaderClassName('menu-scroll');
      } else if (headerClassName === 'menu-scroll' && window.scrollY < 80) {
        setHeaderClassName('');
      }
    }
  }

  useEffect(() => {
    window.onscroll = () => handleScroll(headerClassName);
    if(window.innerWidth < 420) {
      setIsMobile(true)
    }
    //console.log('search : ', pathname)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [headerClassName,isMobile]);

  return (
    <div>
      <div>{ pathname === "/" ? <TopBanner/> : ""}</div>
    <div className={`${pathname === '/' ? 'headerWrap_main' : 'headerWrap'} ${headerClassName}`}>
      <div className="headerContainer">
        <header className={`displayFlex justifyBetween ${pathname === '/' || pathname.includes('events/') ? 'darkTheme' : 'lightTheme'}`}>
          <div className="displayFlex navContainer">
            {isOpen ? null : <h1><NavLink to="/" className="ci-logo">{t('common.ci')}</NavLink></h1>}
            <nav className="mainNav displayFlex justifyBetween">
                <NavLink to="/service" className="nav">{t('navigation.service')}</NavLink>

              {/*<NavLink to="/about" className="nav">{t('navigation.about')}</NavLink>*/}
              <span className="nav hasChild">
                <NavLink to="/company" className="title">{t('navigation.about')}</NavLink>
                 <span className="childNav">
                   <NavLink to="/company" className="nav">{t('navigation.about')}</NavLink>
                   <a href="https://career.sellerhub.co.kr/" target="_blank" rel="noreferrer" className="nav">{t('internalLink.recruit')}</a>
                 </span>
              </span>
              <span className="nav hasChild">
                <NavLink to="/events"><span className="title">{t('navigation.news')}</span></NavLink>
                <span className="childNav">
                  <NavLink to="/events" className="nav">{t('navigation.news')}</NavLink>
                  <a href="https://blog.naver.com/sellerhub0219" target="_blank" rel="noreferrer" className="nav">{t('navigation.blog')}</a>
                </span>
              </span>


              <NavLink to="/helpcenter" className="nav">{t('navigation.help')}</NavLink>
              {/*<span className="nav hasChild">*/}
              {/*  <NavLink to="/faq"><span className="title">{t('navigation.help')}</span></NavLink>*/}
              {/*  <span className="childNav">*/}
              {/*    <NavLink to="/faq" className="nav">{t('navigation.faq')}</NavLink>*/}
              {/*    <NavLink to="/tutorial" className="nav">{t('navigation.tutorial')}</NavLink>*/}
              {/*  </span>*/}
              {/*</span>*/}

              <NavLink to="/pricing" className="nav">{t('navigation.pricing')}</NavLink>
              <a href={`https://sellerhub.notion.site/partners12`} target="_blank" rel="noreferrer" className="nav">{t('navigation.information')}</a>
            </nav>
          </div>

          <nav className="linkNav">
            <a href={`https://admin.sellerhub.co.kr/shop/admin_provider/login/login.php?`+gtmUrl} target="_blank" rel="noreferrer"  id="button-login-pc" className="linkLogin">{t('outerLink.login')}</a>
            <a href={`https://admin.sellerhub.co.kr/shop/admin_provider/member/join_simplification.php?`+gtmUrl} target="_blank" rel="noreferrer"  id="button-join-pc" className="shStart">{t('outerLink.shStart')}</a>
          </nav>
        </header>

        <div className="mobileNavigation">
          {isOpen ? <h1><NavLink to="/" className="ci-logo" onClick={()=> toggleOpen()}>{t('common.ci')}</NavLink></h1> : null}
          <motion.nav
            initial={false}
            animate={isOpen ? "open" : "closed"}
            custom={height}
            ref={containerRef}
            className={isOpen ? "open" : "closed"}
          >
            <motion.div className="background" variants={sidebar} />
            <motion.ul variants={variants} className="mobileMenuItems">
              <motion.li key={1} variants={variantsItems}>
                <NavLink to="/service" className="nav" onClick={()=> toggleOpen()}>{t('navigation.service')}</NavLink>
              </motion.li>
              <motion.li key={9} variants={variantsItems}>
                <NavLink to="/company" className="nav" onClick={()=> toggleOpen()}>{t('navigation.about')}</NavLink>
              </motion.li>
              <motion.li key={10} variants={variantsItems}>
                <a href="https://career.sellerhub.co.kr/" target="_blank" rel="noreferrer" className="nav" onClick={()=> toggleOpen()}>{t('internalLink.recruit')}</a>
              </motion.li>
              {/*<motion.li key={2} variants={variantsItems}><NavLink to="/about" className="nav" onClick={()=> toggleOpen()}>{t('navigation.about')}</NavLink></motion.li>*/}
              <motion.li key={2} variants={variantsItems}>
                <NavLink to="/events" className="nav" onClick={()=> toggleOpen()}>{t('navigation.news')}</NavLink>
              </motion.li>
              <motion.li key={3} variants={variantsItems}>
                <a href="https://blog.naver.com/sellerhub0219" target="_blank" rel="noreferrer" className="nav" onClick={()=> toggleOpen()}>{t('navigation.blog')}</a>
              </motion.li>
              <motion.li key={4} variants={variantsItems}>
                <NavLink to="/helpcenter" className="nav" onClick={()=> toggleOpen()}>{t('navigation.help')}</NavLink>
              </motion.li>
              {/*<motion.li key={5} variants={variantsItems}>*/}
              {/*  <NavLink to="/tutorial" className="nav" onClick={()=> toggleOpen()}>{t('navigation.tutorial')}</NavLink>*/}
              {/*</motion.li>*/}
              <motion.li key={6} variants={variantsItems}>
                <NavLink to="/pricing" className="nav" onClick={()=> toggleOpen()}>{t('navigation.pricing')}</NavLink>
              </motion.li>
              <motion.li key={9} variants={variantsItems}>
                <a href={`https://sellerhub.notion.site/partners12`} target="_blank" rel="noreferrer" className="nav" onClick={()=> toggleOpen()}>{t('navigation.information')}</a>
              </motion.li>
              <motion.li key={7} variants={variantsItems} className="joinUs">
                <a href={`https://admin.sellerhub.co.kr/shop/admin_provider/member/join_simplification_mo.php?`+gtmUrl} target="_blank" rel="noreferrer" id="button-join-mobile" className="nav shStart" onClick={()=> toggleOpen()}>{t('outerLink.shStart')}</a>
              </motion.li>
              <motion.li key={8} variants={variantsItems}>
                <a href={`https://admin.sellerhub.co.kr/shop/admin_provider/login/m_login.php?`+gtmUrl} id="button-login-mobile"  className="nav linkLogin" onClick={()=> toggleOpen()}>{t('outerLink.login')}</a>
              </motion.li>
            </motion.ul>
            <MenuToggle toggle={() => toggleOpen()} />
          </motion.nav>
        </div>
      </div>
    </div>
    </div>
  )
}
export default Header;
